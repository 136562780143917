import { useMemo, ChangeEvent } from 'react'

import { getRandomString } from '../utils/random'
import noop from '../utils/noop'

import Icon from './Icon'
import style from './Checkbox.module.css'

export interface CheckboxProps {
  name: string,
  label?: string,
  id?: string,
  value?: boolean | string[],
  onChange?: (value: this['value']) => void,
  [key: string]: any
}

const Checkbox = ({
  name,
  label,
  id: idProp,
  value,
  onChange: onChangeProp = noop,
  ...attrs
}: CheckboxProps) => {
  const id = useMemo(() => idProp || `${name}__${getRandomString()}`, [idProp, name])
  const checked = useMemo(() => {
    if (Array.isArray(value) && name) {
      return value.includes(name)
    }

    return Boolean(value)
  }, [value, name])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(value) && name) {
      if (!name) return;

      const newValue = [...value];
      const index = newValue.indexOf(name);

      if (!event.target.checked && index >= 0) {
        newValue.splice(index, 1);
      } else if (event.target.checked && index < 0) {
        newValue.push(name);
      }

      onChangeProp(newValue);
    } else {
      onChangeProp(event.target.checked);
    }
  }

  return (
    <div className="flex">
      <input
        id={id}
        type="checkbox"
        name={name}
        className={style.input}
        checked={checked}
        onChange={onChange}
        {...attrs}
      />
      <label htmlFor={id} className={style.label}>
        <span className={style.checkbox}>
          <span className={style.check}>
            <Icon style={{ fontSize: 'inherit' }}>check</Icon>
          </span>
        </span>
        {label}
      </label>
    </div>
  )
}

export default Checkbox
