import { ReactNode } from 'react'

const Icon = ({
  'aria-label': ariaLabel,
  children,
  ...attrs
}: {
  'aria-label'?: string,
  children: ReactNode,
  [key: string]: any
}) => (
  <span role="img" className="material-icons" aria-hidden={!ariaLabel} aria-label={ariaLabel} {...attrs}>
    {children}
  </span>
)

export default Icon