export type NoteSymbol = 'C' | 'D' | 'E' | 'F' | 'G' | 'A' | 'B'
export type AccidentalSymbol = '' | 'b' | '#'
export type NoteName = `${NoteSymbol}${AccidentalSymbol}`

export interface Note {
  natural: NoteName | null,
  sharp: NoteName | null,
  flat: NoteName | null
}

export const NOTES: Note[] = [
  {
    natural: 'C',
    sharp: 'B#',
    flat: null
  },
  {
    natural: null,
    sharp: 'C#',
    flat: 'Db'
  },
  {
    natural: 'D',
    sharp: null,
    flat: null
  },
  {
    natural: null,
    sharp: 'D#',
    flat: 'Eb'
  },
  {
    natural: 'E',
    sharp: null,
    flat: 'Fb'
  },
  {
    natural: 'F',
    sharp: 'E#',
    flat: null
  },
  {
    natural: null,
    sharp: 'F#',
    flat: 'Gb'
  },
  {
    natural: 'G',
    sharp: null,
    flat: null
  },
  {
    natural: null,
    sharp: 'G#',
    flat: 'Ab'
  },
  {
    natural: 'A',
    sharp: null,
    flat: null
  },
  {
    natural: null,
    sharp: 'A#',
    flat: 'Bb'
  },
  {
    natural: 'B',
    sharp: null,
    flat: 'Cb'
  }
]

export const MINOR_SHARPS: Array<string | null> = ['F#', 'C#', 'G#']

export const CHORD_TYPES = [
  'M',
  'm',
  'dim',
  '7',
  'M7',
  'm7',
  'm7b5',
  'dim7',
  'sus2',
  'sus4',
  'add6',
  'add9'
] as const

export type ChordType = typeof CHORD_TYPES[number]
export type Chord = `${NoteName}${ChordType}`
