import { useState, useRef, useEffect, useCallback, ChangeEvent } from 'react'
import range from 'lodash/range'
import clsx from 'clsx'

import packageJson from '../package.json'

import { getRandomArrayValue } from './utils/random'

import {
  getNoteName,
  ChordType,
  Chord,
  Note,
  NOTES,
  MINOR_SHARPS,
  CHORD_TYPES
} from './music'
import settings, { saveSetting } from './settings'

import Checkbox from './components/Checkbox'

import style from './App.module.css'

const MIN_BPM = 10
const MAX_BPM = 300

const clickSound = new Audio('/metronome-click.wav')

const App = () => {
  const [currentChord, setCurrentChord] = useState<Chord | null>(null)

  const [nextChord, setNextChordState] = useState<Chord | null>(null)
  const nextChordRef = useRef<Chord | null>(null)

  const [currentBeat, setCurrentBeatState] = useState<number>(0)
  const currentBeatRef = useRef<number>(0)

  const [playing, setPlaying] = useState<boolean>(false)

  const intervalRef = useRef<number | null>(null)

  const [bpm, setBpmState] = useState<number>(settings.bpm)
  const bpmRef = useRef<number>(settings.bpm)

  const interruptedRef = useRef(false)
  const [audioReady, setAudioReady] = useState<boolean>(false)

  const [volume, setVolume] = useState<number>(settings.volume)
  const [muted, setMuted] = useState<boolean>(settings.muted)

  const [settingsAreOpen, setSettingsAreOpen] = useState<boolean>(false)

  const [chordsSetting, setChordsSetting] = useState<Array<string>>(settings.chords)

  const bpc = 4

  const getRandomNote = () => {
    return getRandomArrayValue(NOTES)
  }

  const getRandomChord = (): Chord => {
    const note: Note = getRandomNote()
    const chord: ChordType = getRandomArrayValue(chordsSetting)
    const noteName = /^m/.test(chord) && MINOR_SHARPS.includes(note.sharp)
      ? note.sharp
      : getNoteName(note)

    if (noteName === null) throw new Error('No note found')

    return `${noteName}${chord}`
  }

  const setNextChord = (value: Chord | null) => {
    nextChordRef.current = value
    setNextChordState(value)
  }

  const selectNextChords = () => {
    if (nextChordRef.current) {
      setCurrentChord(nextChordRef.current)
    }
    setNextChord(getRandomChord())
  }

  const setCurrentBeat = (value: number) => {
    currentBeatRef.current = value
    setCurrentBeatState(value)
  }

  const setBpm = (value: number) => {
    setBpmState(value)
    bpmRef.current = value

    if (playing) {
      interruptedRef.current = true

      stop()
    }

    if (interruptedRef.current) {
      start()
    }
  }

  const playBeat = () => {
    let newBeat

    if (currentBeatRef.current < bpc) {
      newBeat = currentBeatRef.current + 1
    } else {
      newBeat = 1
    }

    clickSound.currentTime = 0;
    clickSound.play()

    if (newBeat === 1) {
      selectNextChords()
    }

    setCurrentBeat(newBeat)
  }

  const start = () => {
    if (intervalRef.current != null || bpmRef.current < MIN_BPM || bpmRef.current > MAX_BPM) return

    interruptedRef.current = false
    setPlaying(true)

    playBeat()

    intervalRef.current = window.setInterval(playBeat, (1000 * 60) / bpmRef.current)
  }

  const stop = () => {
    if (!interruptedRef.current) {
      setPlaying(false)
    }

    if (intervalRef.current != null) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }

    setCurrentBeat(0)
    setCurrentChord(null)
    setNextChord(null)
  }

  const changeBpm = (value: number) => {
    setBpm(value)
    if (value >= MIN_BPM && value <= MAX_BPM) saveSetting('bpm', value)
  }

  const changeMuted = (value: boolean) => {
    setMuted(value)
    saveSetting('muted', value)
  }

  const changeVolume = (value: number) => {
    setVolume(value)
    saveSetting('volume', value)
  }

  const onAudioReady = useCallback(() => {
    setAudioReady(true)
  }, [])

  useEffect(() => {
    return () => {
      if (intervalRef.current != null) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (clickSound.readyState === HTMLMediaElement.HAVE_ENOUGH_DATA) {
      onAudioReady()
    } else {
      clickSound.addEventListener('canplaythrough', onAudioReady)
    }

    return () => {
      clickSound.removeEventListener('canplaythrough', onAudioReady)
    }
  }, [onAudioReady])

  useEffect(() => {
    clickSound.muted = muted
    clickSound.volume = volume / 100
  }, [muted, volume])

  const onBpmChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value, 10)
    value = (isNaN(value) || value < 0)
      ? 0
      : value

    changeBpm(value)

    event.target.value = value.toString()
  }

  const onButtonClick = () => {
    interruptedRef.current = false

    if (playing) stop()
    else start()
  }

  const onVolumeButtonClick = () => {
    changeMuted(!muted)
  }

  const onVolumeDecreaseClick = () => {
    changeVolume(volume > 0 ? volume - 10 : 0)
  }

  const onVolumeIncreaseClick = () => {
    changeVolume(volume < 100 ? volume + 10 : 100)
  }

  const onSettingsClick = () => {
    setSettingsAreOpen(!settingsAreOpen)
  }

  const onChordsSettingChange = (value: any) => {
    if (!Array.isArray(value)) throw new Error('expected string[]')

    if (value.length === 0) return

    setChordsSetting(value)
    saveSetting('chords', value)
  }

  return (
    <div className="App">
      <div className="container mx-auto text-center flex flex-col min-h-full p-4">
        <h1 className="text-2xl">
          Random Chords Trainer
        </h1>
        <div className="flex-shrink-0 m-auto py-10">
          <div className="relative w-40 text-3xl h-[1em] leading-none">
            {currentChord && (
              <p>
                {currentChord}
              </p>
            )}
            {nextChord && (
              <p className="absolute text-base left-full top-0 bottom-0 mt-auto mb-auto h-[1em] ml-4 leading-none">
                {nextChord}
              </p>
            )}
          </div>
          <div className="mt-6 flex mx-auto justify-center">
            {range(0, bpc).map((number) => (
              <span
                className={clsx(
                  'mx-2 rounded-full block h-3 w-3 border border-indigo-500 border-style-solid',
                  { "bg-indigo-500": number + 1 === currentBeat }
                )}
                key={number}
              ></span>
            ))}
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <div className="">
              <div>
                <label htmlFor="bpm" className="leading-7 text-sm text-gray-600">BPM</label>
                <input
                  type="number"
                  id="bpm"
                  min={MIN_BPM}
                  max={MAX_BPM}
                  className={style.textField}
                  onChange={onBpmChange}
                  value={bpm}
                />
              </div>
              <div className="mt-2">
                <button type="button" onClick={onVolumeButtonClick} className="text-indigo-500">
                  {muted ? (
                    <span role="img" aria-label="Unmute" className="material-icons block">volume_off</span>
                  ) : (
                    <span role="img" aria-label="Mute" className="material-icons block">volume_up</span>
                  )}
                </button>
                <div className="flex justify-center">
                  <div className="relative text-xs">
                    <button type="button" className="absolute mr-2 top-0 bottom-0 right-full my-auto" onClick={onVolumeDecreaseClick}>
                      <span role="img" aria-label="Decrease volume" className="material-icons block text-[1.5em]">remove</span>
                    </button>
                    <p title="Volume" className="w-6 h-6 flex items-center justify-center">
                      {volume}
                    </p>
                    <button type="button" className="absolute ml-2 top-0 bottom-0 left-full my-auto" onClick={onVolumeIncreaseClick}>
                      <span role="img" aria-label="Increase volume" className="material-icons block text-[1.5em]">add</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <button
              type="button"
              className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 disabled:bg-gray-300 rounded text-lg"
              onClick={onButtonClick}
              disabled={!audioReady}
            >
              {playing ? (
                'Stop'
              ) : (
                'Start'
              )}
            </button>
          </div>
          <div className="mt-2">
            <button type="button" className="underline text-indigo-500" onClick={onSettingsClick}>
              Settings
            </button>
          </div>
          {settingsAreOpen && (
            <div className="mt-8 text-left mx-auto lg:w-96">
              <ul className="grid grid-flow-col gap-1" style={{
                gridTemplateColumns: `1fr min-content`,
                gridTemplateRows: `repeat(${Math.ceil(CHORD_TYPES.length / 2)}, min-content)`
              }}>
                {CHORD_TYPES.map(chordType => (
                  <li>
                    <Checkbox
                      label={chordType}
                      name={chordType}
                      onChange={onChordsSettingChange}
                      value={chordsSetting}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="mt-8">
            Made by <a href="https://simon-martineau.com" className="text-indigo-500 underline">Simon Martineau</a>. v{packageJson.version}.
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
