const defaultSettings = {
  chords: [
    'M',
    'm',
    '7',
    'M7',
    'm7',
    'sus4'
  ],
  bpm: 100,
  muted: false,
  volume: 100
}

const getSettings = () => {
  try {
    const parsedSettings = JSON.parse(localStorage.getItem('settings') || '')

    if (
      !parsedSettings ||
      typeof parsedSettings !== 'object' ||
      Array.isArray(parsedSettings)
    ) return defaultSettings

    return parsedSettings as object
  } catch (error) {
    return defaultSettings
  }
}

const settings: {
  [key: string]: any
} = getSettings()

export const saveSetting = (key: string, value: any) => {
  settings[key] = value

  localStorage.setItem('settings', JSON.stringify(settings))
}

export default settings
