export const getRandomInt = (min: number = 0, max: number = 1) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) // The maximum is exclusive and the minimum is inclusive
}

export const getRandomArrayValue = (array: readonly any[]) => {
  return array[getRandomInt(0, array.length)]
}

export const getRandomString = () => {
  return Math.random().toString(36).substring(7)
}
